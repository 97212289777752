import React from "react";

export default function BookNow() {
    return (
        <div className="bookNowPage, content">
            <h1>Book Now</h1>
            <br></br>
            <div className='nav-buttons'>
                <a href="https://schedule.nylas.com/pixelcooksites" target="_blank" rel="noopener noreferrer">
                    <button className="nav-button">
                        <i className="bi bi-unlock-fill"></i> <b>Book Now</b>
                    </button>
                </a>
            </div>
        </div>
    )
}