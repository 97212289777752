import React from "react";
// import Carousel from "react-bootstrap/Carousel";
// import solarPanelKitImage from '../../assets/images/solarPanelKit.png';
import { servicesInfo } from "../../data/webData";

export default function Services() {

  return (
    <div>
      <h1>Website Development Packages</h1>     
      <div className="services-page">
        {servicesInfo.map((data) =>
          <div key={data.id} className="card">
            <div className="card-content">
              <div className="price-tag">{data.priceTag}</div>
              <h3>{data.title}</h3>
              <p>{data.description}</p>
              <ul>
                {data.deliverables.map((deliverableList, i) =>
                  <li key={i}>{deliverableList}</li>
                )}
              </ul>
              <a href="/booknow" rel="noopener noreferrer">
                <button className="purchase-button">
                  <b>Start Project</b>
                </button>
              </a>
            </div>
          </div>
        )}
      </div>

      <br></br>
      <div className="marketing-services">
          <h1>Digital Marketing Services Packages</h1>
          <p>our digital marketing offerings</p>
        </div>

    </div>
  )
}