import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';

// import { NylasProvider } from '@nylas/nylas-react';
// const SERVER_URI = import.meta.env.VITE_SERVER_URI || 'http://localhost:9000';

import 'bootstrap/dist/css/bootstrap.min.css';
import "@fortawesome/fontawesome-free/css/all.min.css";
import "bootstrap-css-only/css/bootstrap.min.css";
import "mdbreact/dist/css/mdb.css";

import App from './App';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>
  //   <NylasProvider serverBaseUrl={SERVER_URI}>
  //   <App />
  //   </NylasProvider>
  // </React.StrictMode>
  // <BrowserRouter>
  //   <App />
  // </BrowserRouter>
    <React.StrictMode>
      <App />
    </React.StrictMode>
);

reportWebVitals();
