import React from "react";

export default function Services() {
    return (
        <div className="storyPage">
            <h1>Our Story</h1>
            <i className="fas fa-location-dot"></i>
            {/* <h3>We Serve Decatur, Duluth, & Buckhead</h3> */}
            <h3>Based in Atlanta, GA</h3>
            <hr></hr>
            <p>Our mission is to provide the highest quality websites and apps for our clients.</p>
        </div>
    )
};