import React, {useRef, useEffect} from 'react';
import { Link } from 'react-router-dom';
import { clientInfo, siteContent } from '../../data/webData';
import video from '../../assets/images/1082268005-hd.mov';
import Poster from '../../assets/images/video-thumbnail.png';

export default function Homepage() {
  // const videoRef = useRef(undefined);
  // useEffect(() => {
  //   videoRef.current.defaultMuted = true;
  // })

  const videoData = 
    `<video loop='' autoPlay='' poster=${Poster} playsInline >
      <source src=${video} type='video/mp4' />
      <img src=${Poster} alt=''></img>
      Your browswer does not support the video tag. I suggest you upgrade your browser.
    </video>`;

  return (
    // <div className='homepageBackgroundImage' style={{
    //   backgroundImage:`url(${siteContent[0].homepageBanner})`,
    //   backgroundRepeat: 'no-repeat', 
    //   backgroundSize:'cover', 
    //   padding: '20%',
    // }}>
    // <div className='homepageBackgroundPlaceholder'>
    <div className='homePage'>
      <div className='overlay'></div>

      <div
        dangerouslySetInnerHTML={{__html: videoData}} 
      />

      {/* <video loop='' autoPlay='' poster={Poster} playsInline >
        <source src={video} type='video/mp4' />
        <img src={Poster} alt=''></img>
        Your browswer does not support the video tag. I suggest you upgrade your browser.
      </video> */}

      <div className='homePageContent'>
        <h1>PixelCook Sites</h1>
        <i className="fas fa-screwdriver"></i>
        <p>Artisans of the Digital World</p>
        <Link to="https://schedule.nylas.com/pixelcooksites">
          <button className="purchase-button-homepage">
            <b>{clientInfo[0].callToAction}</b>
          </button>
        </Link>
      </div>

    </div>
  );
}