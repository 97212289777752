const siteContent = [
  {
    id: 1,
    homepageBanner: require('../assets/images/stock-photo-focused-young-man-wearing-glasses-using-laptop-typing-on-keyboard-writing-email-or-message-1673456497.jpg')
  }
]

// homepageBanner ideas:
// pixel_video.mp4
// stock-photo-focused-young-man-wearing-glasses-using-laptop-typing-on-keyboard-writing-email-or-message-1673456497.jpg

const clientInfo = [
    {
        id: 1,
        clientName: 'PixelCook Sites',
        phoneNumber: '6786267334',
        email: 'info@pixelcooksites.com',
        businessLocation: 'Atlanta, GA',
        websiteLogo: require('../assets/images/logo.png'),
        callToAction: 'Schedule a Consult',
        callToActionLink: 'https://schedule.nylas.com/pixelcooksites'
    }
]

const teamInfo = [
    {
        id: 1,
        name: 'Alex',
        title: 'Founder',
        description: '',
        profilePic: require('../assets/images/work-profile-pic.png')
    }
]

const portfolioWork = [
  {
    id: 1,
    title: 'leash-me-out',
    thumbnail: require('../assets/images/portfolio/leash-me-out-thumbnail.png'),
    webImage: require('../assets/images/portfolio/leash-me-out.png')
  },
  {
    id: 2,
    title: 'classic-city-green-solutions',
    thumbnail: require('../assets/images/portfolio/classic-city-green-solutions-thumbnail.png'),
    webImage: require('../assets/images/portfolio/classic-city-green-solutions.png')
  },
]

const servicesInfo = [
    {
      id: 1,
      title: 'Startup Website Package',
      description: 'Suitable for startups and brand revamps for companies',
      priceTag: '$125',
      // cardImage: require('../assets/images/dog-paw.png'),
      deliverables: [
        'Up to 3 Page Website',
        'Up to 5 Stock Images (Industry Specific)',
        'Contact Form',
        'jQuery Slider',
        '3 Banner Designs',
        'Google Friendly Sitemap',
        'Complete W3C Certified HTML',
        '48 to 72 hours Turnaround Time',
        'Satisfaction Guarantee',
        'Money Back Guarantee',
        'Mobile Responsive for $125',
        'CMS for Additional $150'
      ],
      // callToAction: 'Start Project'
    },
    {
      id: 2,
      title: 'Professional Website Package',
      description: 'Suitable for startups and brand revamps for companies',
      priceTag: '$594',
      // cardImage: require('../../assets/images/barn.png'),
      deliverables: [
        'Up to 10 Unique Pages Website',
        'CMS (Content Management System) / Admin Panel',
        'Contact Form',
        'Online Appointment Scheduling CTA Form',
        'Book a Call CTA Form',
        'Up to 5 Stock Images (Industry Specific)',
        '5 Cutsom Banner Designs',
        'jQuery Slider',
        'FREE Google Friendly Sitemap',
        'Complete W3C Certified HTML',
        '48 to 72 hours Turnaround Time',
        'Complete Deployment',
        '100% Satisfaction Guarantee',
        '100% Money Back Guarantee',
        'Mobile Responsive for additional $139',
      ],
      // callToAction: 'Start Project'
    },
  ]

export { clientInfo, servicesInfo, teamInfo,  siteContent, portfolioWork };