import React from "react";

import Carousel from "react-bootstrap/Carousel";

export default function Portfolio() {
    const slideshow = [
        {
            id: 1,
            thumbnail: require('../../assets/images/portfolio/leash-me-out-thumbnail.png'),
            webImage: require('../../assets/images/portfolio/leash-me-out.png')
        },
        // {
        //     id: 2,
        //     image: require('../../assets/images/IMG_1089.png')
        // },
        // {
        //     id: 3,
        //     image: require('../../assets/images/carousel/66491323011__6C57BA4A-9089-4591-9DFF-F3679AD77E21.png')
        // },
        // {
        //     id: 4,
        //     image: require('../../assets/images/carousel/69291495874__B2CF114D-6AC6-4CC2-A384-BAC9F70AAE05.png')
        // },
        // {
        //     id: 5,
        //     image: require('../../assets/images/carousel/70594754093__85193C2E-7AEA-4585-A382-F385BAE330AA.png')
        // },
        // {
        //     id: 6,
        //     image: require('../../assets/images/carousel/IMG_0290.png')
        // },
    ]
    return (
        <div className="slideShow">
            <h1>Portfolio</h1>

            <div className="team-profile-list">
                <div className="portfolio-button">BUSINESS</div>
                <div className="portfolio-button">E-COMMERCE</div>
                {/* <div className="portfolio-button">PARALLAX</div>
                <div className="portfolio-button">WEB PORTAL</div> */}
            </div>

            <div className="team-profile-list">

            </div>

            {/* 
            
            BUSINESS, E-COMMERCE, PARALLAX, WEB PORTAL, WEB APPLICATIONS
            
            */}

            {/* <img
                src={slideshow[0].image}
                alt="slideShowImage"
                className="slideshow-image"
            /> */}

            <div className="team-profile-list" style={{marginTop: '25px'}}>
                <div className="portfolio-thumbail">
                    <a href={slideshow[0].webImage}>
                        <img
                            src={slideshow[0].thumbnail}
                            alt="slideShowImage"
                            className="slideshow-image"
                        />
                    </a>
                </div>
            </div>

            

        </div>
    )
}