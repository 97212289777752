import React from "react";
import Carousel from "react-bootstrap/Carousel";
import { portfolioWork } from '../../data/webData';

export default function Work() {
  return (
    <div>
        <h1>Work Portfolio</h1>
        <Carousel>
        {/* <Carousel.Item>
                
            <div className="card">
            <div className="card-content">
                <h3>Web Development</h3>
                <p>We will custom build a website based on your specific needs</p>
                <a href="https://buy.stripe.com/test_eVa6p87bXfx3d8I4gg" target="_blank" rel="noopener noreferrer">
                <button className="purchase-button">
                    Get a Quote Today
                </button>
                </a>
            </div>
            </div>

            <Carousel.Caption>
            </Carousel.Caption>
        </Carousel.Item>

        <Carousel.Item>

            <div className="card">
            <div className="card-content">
                <h3>Web Development</h3>
                <p>We will custom build a website based on your specific needs</p>
                <a href="https://buy.stripe.com/test_eVa6p87bXfx3d8I4gg" target="_blank" rel="noopener noreferrer">
                <button className="purchase-button">
                    Get a Quote Today
                </button>
                </a>
            </div>
            </div>

            <Carousel.Caption>
            </Carousel.Caption>
        </Carousel.Item> */}

        {/* <div>
            {portfolioWork.map((data) =>
                <div key={data.id}>

                </div>
            )}
        </div> */}

        <Carousel.Item>
            <div>
                <div className="card-content">
                <div className="portfolio-thumbail">
                        <a href={portfolioWork[0].webImage}>
                            <img
                                src={portfolioWork[0].thumbnail}
                                alt="slideShowImage"
                                className="slideshow-image"
                            />
                        </a>
                    </div>
                </div>
            </div>
            <Carousel.Caption>
            </Carousel.Caption>
        </Carousel.Item>

        <Carousel.Item>
            <div>
                <div className="card-content">
                <div className="portfolio-thumbail">
                        <a href={portfolioWork[1].webImage}>
                            <img
                                src={portfolioWork[1].thumbnail}
                                alt="slideShowImage"
                                className="slideshow-image"
                            />
                        </a>
                    </div>
                </div>
            </div>
            <Carousel.Caption>
            </Carousel.Caption>
        </Carousel.Item>

        </Carousel>
    </div>
  )
}