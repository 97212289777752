import React from "react";
import { Link } from 'react-router-dom';

import { clientInfo } from '../../data/webData';

export default function NextSteps() {
    return (
        <div className="nextSteps">
            <h1>Your Next Steps</h1>
            <ol>
                <li>Schedule a Consult</li>
                <li>Receive Feedback</li>
                <li>Action = Money</li>
            </ol>
            <Link to="https://schedule.nylas.com/pixelcooksites">
                <button className="purchase-button-homepage">
                    <b>{clientInfo[0].callToAction}</b>
                </button>
            </Link>
        </div>
    )
}