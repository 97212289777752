import React from 'react';
import { teamInfo } from '../../data/webData';

export default function About() {
  return (
    <div className='about-page'>
      <h1>Meet the Team</h1>
        <div className='team-profile-list'>
          {teamInfo.map((data) => 
          
          <div key={data.id} className='team-profile'>
            <div className='team-profile-content'>
              <img
                src={data.profilePic}
                alt="About Thumbnail"
                className="team-profile-thumbnail"
              />
              <div className='name'>{data.name}</div>
              <div className='profession'>{data.title}</div>
              <div className='profileDescription'>{data.description}</div>
              {/* <ul>
                <li>
                  <img
                    src={data.profilePic}
                    alt="About Thumbnail"
                    className="team-profile-thumbnail"
                  />
                </li>
                <li className='name'><b>{data.name}</b></li>
                <li className='profession'>{data.title}</li>
                <li className='profileDescription'>{data.description}</li>
              </ul> */}
            </div>
          </div>

          )}
        </div>
    </div>
  );
}
