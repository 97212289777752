import React from "react";
// import logo from '../../assets/images/logo.png';
import { clientInfo } from "../../data/webData";

import Signature from './Signature';

export default function FooterPage() {

    return (
        <div>
            {/* {console.log(clientInfo[0])} */}
            <footer className='footer'>
                <a href={`tel:${clientInfo[0].phoneNumber}`} className='contact-link'>
                    <i className="bi bi-telephone-fill"></i> {clientInfo[0].phoneNumber.slice(0,3)}-{clientInfo[0].phoneNumber.slice(3,6)}-{clientInfo[0].phoneNumber.slice(6,10)}
                </a>
                <a href={`mailto:${clientInfo[0].email}`}  className='contact-link'>
                    <i className="bi bi-envelope-fill"></i> {clientInfo[0].email}
                </a>
                <p>
                <i className="bi bi-building-fill"></i> {clientInfo[0].businessLocation}
                </p>
                <div className='logo'>
                    <a href="/#home">
                        {/* <img src={clientInfo[0].websiteLogo} alt="logo"></img> */}
                        {clientInfo[0].clientName}
                    </a>
                </div>
                <br></br>
                <p>Copyright © {clientInfo[0].clientName}. All Rights Reserved {new Date().getFullYear()}</p>

                <Signature />

            </footer>
    </div>
    )
};