import React from "react";

export default function Resume() {
    return (
        <div className="resumePage">
            <h1>Languages</h1>
            <ul>
                <li>HTML</li>
                <li>CSS</li>
                <li>Javascript</li>
                <li>React JS</li>
            </ul>
        </div>
    )
};