import React from 'react';
import { Link } from 'react-router-dom';

import { clientInfo } from '../data/webData';

import Homepage from './pages/Homepage';
import Story from './pages/Story';
import Services  from './pages/Services';
import About from './pages/About';
import Contact from './pages/Contact';
import Portfolio  from './pages/Portfolio';
// import Slideshow from './pages/Slideshow';
import NextSteps from './pages/NextSteps';
// import Calendar from './pages/Calendar';
import CallToAction from './pages/CallToAction';
import Resume from './pages/Resume';
import Work from './pages/Work';

export default function PortfolioContainer() {
  return (
    <div className="App-header">
      
      {/* <Navbar /> */}
      <div id="home" className="content"><Homepage /></div>
      <div id="story" className="content"><Story /></div>
      {/* <div id="portfolio" className="content"><Portfolio /></div> */}
      <div id='work' className="content"><Work /></div>
      <div id='nextsteps' className="content"><NextSteps /></div>
      {/* <div id='services' className="content"><Services /></div> */}
      <div id='about' className="content"><About /></div>
      <div id='contact' className="content"><Contact /></div>
      <div id='callToAction' className="content"><CallToAction /></div>
      {/* <div id='calendar' className="content"><Calendar /></div> */}
      {/* <div className='content'>
        <Link to="/booknow">
          <button className="purchase-button-homepage">
            <b>{clientInfo[0].callToAction}</b>
          </button>
        </Link>
      </div> */}

    </div>
  );
}