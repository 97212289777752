// import React, { useEffect, useState } from "react";
// import ReactDom from "react-dom/client";
// import { Link } from 'react-router-dom';
import { clientInfo } from "../data/webData";

// import logo from '../assets/images/logo.png';

export default function Navbar() {
  // const [show, setShow] = useState(true)
  // const controlNavbar = () => {
  //   if (window.scrollY > 950) {
  //     setShow(false)
  //   } else {
  //     setShow(true)
  //   }
  // }

  // useEffect(() => {
  //   window.addEventListener('scroll', controlNavbar)
  //   return () => {
  //     window.removeEventListener('scroll', controlNavbar)
  //   }
  // }, [])

  return (
      // <div className={"App-header"}>
      <div className="App-header">
      {/* <nav className={`nav-bar ${isNavOpen ? 'active' : ''}`}> */}
      {/* <nav className={"nav-bar"} style={{navbarStyles, top: visible ? '0' : '-60px'}}> */}
      <nav className="nav-bar">
      {/* <nav className={`nav-bar ${show && 'nav___blue'}`}> */}
      {/* <nav className={"nav-bar" + hState}> */}
        <div className='top-row'>
          <div className='contact-links'>
            <a href={`tel:${clientInfo[0].phoneNumber}`} className='contact-link'>
              <i className="bi bi-telephone-fill"></i>
              {clientInfo[0].phoneNumber.slice(0,3)}-{clientInfo[0].phoneNumber.slice(3,6)}-{clientInfo[0].phoneNumber.slice(6,10)}
            </a>
            <a href={`mailto:${clientInfo[0].email}`} className='contact-link'>
              <i className="bi bi-envelope-fill"></i> {clientInfo[0].email}
            </a>
          </div>
          <div className='nav-buttons'>
            <a href="https://schedule.nylas.com/pixelcooksites" target="_blank" rel="noopener noreferrer">
              <button className="nav-button">
              <b>{clientInfo[0].callToAction}</b>
              </button>
            </a>
          </div>
        </div>

        <div className='nav-header'>
          <h1 className='nav-title'>
            <div className='logo'>
              {/* <a href="/#home">Leash Me Out</a> */}

              <a href="/#home">
                {/* <img src={logo} alt="Leash Me Out"></img> */}
                {clientInfo[0].clientName}
              </a>

            </div>
          </h1>

          {/* <ul className={`nav-list ${isNavOpen ? 'active' : ''}`}> */}
          <ul className={"nav-list"}>
            <li>
              <a href="/#home">Home</a>
            </li>
            {/* <li>
              <a href='/#services'>Services</a>
            </li> */}
            <li>
              <a href='/#about'>About</a>
            </li>
            <li>
              <a href='/#contact'>Contact</a>
            </li>
          </ul>

        </div>

      </nav>

    </div>
  )
}